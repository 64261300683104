<template>
  <v-dialog
    v-model="instructionModal"
    max-width="800px"
    width="90vw"
    @click:outside="$emit('update:instructionModal', false)"
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2"> Инструкция </v-card-title>

      <v-card-text>
        <div>
          При заливке стеллажей требуется придерживаться данных правил, иначе
          загрузка файла будет невозможна:
        </div>
        <br />
        <ul>
          <li>
            В столбце под названием "Стеллаж №" должны быть только числовые
            значения(1,2,3...).
          </li>
          <li>
            В столбце под названием "Оборудование" должны быть только
            строковые(буквы) значение(Например: холодильник, стеллаж).
          </li>
          <li>
            В столбце под название "Категория" должны быть только
            строковые(буквы) значения(Например: Фруктовое[608], [608]).
          </li>
        </ul>
        <br />
        <div>
          При добавлении новых стеллажей требуется указывать номер стеллажа под
          столбцом "Стеллаж №", вид оборудования под столбцом "Оборудование" и
          категория стеллажа под столбцом "Категория".
        </div>
        <br />
        <div>
          При добавлении полки к новому стеллажу или же к существующему, нужно
          создать строку под стеллажом или же под существующими полками стеллажа
          для которого вы хотите добавить полку.
        </div>
        <br />
        <div>
          Затем указываем категорию полки под столбцом "Категория", в формате -
          Название категории[id категории] или [id категории], обязательно
          указываем квадратные скобки, иначе получим ошибку.
        </div>
        <br />
        <div>
          Чтобы добавить к полке товар, нужно в ячейке справа от категории или в
          конце всех существующих товаров добавить id продукта, в формате
          числа(Например: 12).
        </div>
        <br />
        <div>
          При успешной попытке загрузки стеллажей вы можете выгрузить excel и
          увидеть ваши внесённые изменения в систему стеллажей.
        </div>
        <br />
        <div>
          Форматирование и заливка ячеек занимается программа, вам не требуется
          заливать ячейки или их как-то форматировать.
        </div>
        <br />
        <div>
          Главное правило, стеллажи должны быть цифровыми значениями, а
          категории строковыми.
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="error"
          text
          @click="$emit('update:instructionModal', false)"
        >
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "InsructionModalComp",
  props: {
    instructionModal: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
